

















import { Component, Vue, Prop } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'RoomLocation',
  components: {
    BaseIcon,
  },
})
export default class extends Vue {
  @Prop({ type: String, required: true })
  address!: string

  mounted(): void {
    const element = this.$refs['address'] as HTMLElement
    this.isEllipsis =
      element.clientWidth < element.scrollWidth ||
      element.clientHeight < element.scrollHeight
  }

  isEllipsis = false
}
